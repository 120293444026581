<template>
    <div>
        <div>
            <h3 class="pl-1 pt-2">{{ editorTitle }}</h3>
            <div>
                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('orders.contract') }}</span>
                        <b-form-group
                            class="title"
                            label-for="contract"
                        >
                            <span class="item-detail-text">{{ materialReport.contract ? materialReport.contract.name : "" }}</span>
                        </b-form-group>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('orders.order') }}</span>
                        <b-form-group
                            class="title"
                            label-for="order"
                        >
                            <b-form-select
                                id="order"
                                v-model="order"
                                size="md"
                                class="sm-3"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('menu.select_order') }}
                                    </option>
                                </template>
                                <option
                                    v-for="option in orders"
                                    :key="option.id"
                                    :value="option"
                                >
                                    {{ option.contract_task_type.task_type.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </b-row>

                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('contract_reports.date') }}</span>
                        <b-form-group
                            class="title"
                            label-for="date"
                        >
                            <b-form-input
                                id="date"
                                v-model="materialReport.reporting_date"
                                class=""
                                type="date"
                                size="md"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('material_reports.material') }}</span>
                        <b-form-group
                            class="title"
                            label-for="material"
                        >
                            <b-form-input
                                id="material"
                                class="pl-1"
                                v-model="materialReport.material"
                                type="text"
                                autofocus
                                :placeholder="$t('material_reports.material')"
                                size="md"
                            />
                        </b-form-group>
                    </div>
                </b-row>

                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('material_reports.amount') }}</span>
                        <b-form-group
                            class="title"
                            label-for="amount"
                        >
                            <b-form-input
                                id="amount"
                                v-model="materialReport.amount"
                                type="number"
                                :placeholder="$t('material_reports.amount')"
                                size="md"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('orders.unit') }}</span>
                        <b-form-group
                            class="nopads mt-1 ml-1 mr-2"
                            label-for="unit"
                        >
                            <b-form-select
                                id="unit"
                                v-model="materialReport.unit"
                                size="md"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('unit.select_unit') }}
                                    </option>
                                </template>
                                <option
                                    v-for="option in unitOptions"
                                    :key="option.text"
                                    :value="option.value"
                                >
                                    {{ option.text }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </b-row>

                <b-row>
                    <div class="col-sm-12 nopads">
                        <span class="item-detail-title">{{ $t('common.info') }}</span>
                        <b-form-group
                            class="title"
                            label-for="info"
                        >
                            <b-form-textarea
                                id="info"
                                v-model="materialReport.info"
                                :rows="3"
                                :max-rows="6"
                            />
                            <div v-if="showInfoValidation" class="text-danger">
                                {{ $t('material_reports.info_warning') }}
                            </div>
                        </b-form-group>
                    </div>
                </b-row>

                <div class="button-container col-sm-12 trip-buttons">
                    <b-button
                        variant="secondary"
                        class="form-button"
                        @click.stop="close"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="form-button"
                        @click.stop="addMaterialReport"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import {EventBus} from "@/event-bus";

export default {
    name: 'ContractMaterialReportEditor',
    mixins: [restApi],
    props: {
        materialReport: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            orders: [],
            order: null,
            showInfoValidation: false,
            unitOptions : [
                {value: 'kpl', text: this.$t('unit.pcs')},
                {value: 'kg', text: this.$t('unit.kg')},
                {value: 't', text: this.$t('unit.tons')},
                {value: 'm', text: this.$t('unit.meters')},
                {value: 'km', text: this.$t('unit.km')},
                {value: 'm2', text: this.$t('unit.square_m')},
                {value: 'm3', text: this.$t('unit.cubic_m')},
                {value: 'l', text: this.$t('unit.liter')},
            ],
            panelIndex: 0
        }
    },
    watch: {
        'materialReport.info': function(newValue) {
            // Only validate if `id > 0`
            if (this.materialReport.id > 0) {
                this.showInfoValidation = newValue === '' || newValue.length < 10;
            } else {
                this.showInfoValidation = false;
            }
        },
    },
    mounted() {
        if(this.materialReport && this.materialReport.contract && this.materialReport.contract.id){
            this.fetchContractOrders()
        }
    },
    computed: {
        editorTitle() {
            return this.materialReport.id < 0
                ? this.$t('material_reports.editor_title_new')
                : this.$t('material_reports.editor_title_existing');
        }
    },
    methods: {
        fetchContractOrders: function () {
            this.orders = []
            this.loading = true
            this.restFetchParams(this.orderUrl, {contract: this.materialReport.contract.id}, this.handleOrders)
        },
        handleOrders: function (response) {
            if (response != null) {
                this.orders = response.data
                if (this.orders.length > 0 && this.materialReport.work_order) {
                    this.order = this.orders.find(o => o.id === this.materialReport.work_order.id)
                }
                this.loading = false
            }
        },
        addMaterialReport() {
            if(this.materialReport.id > 0 && (this.materialReport.info === '' || this.materialReport.info.length < 10)) {
                EventBus.$emit('show-alert', this.$t('material_reports.info_warning'))
                return undefined;
            }
            if(this.order) {
                this.materialReport.work_order = this.order
            }
            this.$emit('emitAddMaterialReport', this.materialReport)
        },
        close: function () {
            this.$emit('close')
        },

    }
}
</script>

