<template>
    <multiselect
        v-model="vehicle"
        class="multiselect_routa"
        :placeholder="$t('trip_list.select_vehicle')"
        :deselect-label="$t('common.remove_selection')"
        :select-label="$t('common.select')"
        :close-on-select="true"
        label="name"
        track-by="name"
        :searchable="true"
        :internal-search="true"
        :selected-label="$t('common.selected')"
        :options="vehicleOptions"
    >
        <template
            slot="singleLabel"
            slot-scope="props"
        >
            <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
            </span>
        </template>
        <template
            slot="option"
            slot-scope="props"
        >
            <div class="option__desc">
            <span class="option__title multiselect_detail_block">{{
                props.option.name
                }}</span>
            </div>
        </template>
        <span slot="noResult">{{ $t('common.not_found') }}</span>
        <span slot="noOptions">{{ $t('common.no_results') }}</span>
    </multiselect>
</template>
<script>
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: 'VehicleSelectorDropdown',
    components: {},
    mixins: [restApi],
    props: {
        vehicleId: {
            type: Number,
            default: null
        }
    },
    watch: {
        vehicle: function () {
            const selectedVehicle = this.vehicle ? this.vehicle.value : null
            this.$emit('onVehicleSelected', selectedVehicle)
        }
    },
    data: function () {
        return {
            vehicle: null,
            vehicleOptions: [],
            vehiclesLoaded: false
        }
    },
    mounted: function () {
        this.getVehicles()

    },
    methods: {
        getVehicles: function () {
            this.restFetch(this.vehicleUrl, this.handleVehicles)
        },
        handleVehicles: function (response) {
            if (response && response.data) {
                this.vehicleOptions = response.data.map(function (item) {
                    return {
                        value: item,
                        name: item.make + ' ' + item.vehicle_model + ' - ' + item.license_plate
                    }
                })
                if(this.vehicleId) {
                    this.vehicle = this.vehicleOptions.find(vehicle => vehicle.value.id === this.vehicleId)
                }
            }
            this.vehiclesLoaded = true
        }
    }
}
</script>