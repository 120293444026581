<template>
    <div class="col-sm-12 editor-form nopads w-100">
        <div class="col-sm-12 form-title editor-title">
            <b-row
                class="nopads">
                    <h2 class="col-sm-6 nopads pt-2 pb-2 form-title editor-title">{{$t('work_report.contract_diary')}}</h2>
            </b-row>
        </div>
        <b-row class="nopads">
            <div class="col-sm-4 pr-2">
                <span class="span-title">{{ $t('contract_diary.date') }}</span>
                <b-form-input
                    id="record_date"
                    class="mt-1"
                    v-model="contractDiary.record_date"
                    type="date"
                    size="sm"
                />
            </div>
            <transition name="fade">
                <div v-if="elyVisible" class="col-sm-4 pr-2" style="padding-top: .2em">
                    <span class="span-title">{{ $t('contract_diary.duty_officer') }}</span>
                    <b-form-group
                        label-for="duty_officer"
                    >
                        <b-form-select
                            id="duty_officer"
                            v-model="dutyOfficer"
                            size="sm"
                            :state="dutyOfficer !== null"
                            :disabled="!users || users.length === 0"
                        >
                            <option :value="null">
                                {{ $t('contract_diary.select_duty_officer') }}
                            </option>
                            <option
                                v-for="option in users"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.first_name + ' ' + option.last_name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </transition>
            <div class="col-sm-4 nopads">
                <open-contract-filter
                    ref="contracts"
                    :contract="contract"
                    :from="now"
                    :show-title="true"
                    :show-company="false"
                    :apply-padding="false"
                    @contractSelected="setContract"/>
            </div>
            <div v-if="!elyVisible" class="col-sm-4 nopads" style="padding-top: .2em">
                <span class="span-title">{{ $t('contract_diary.supervisors') }}</span>
                <b-form-group
                    class="title nopads"
                    label-for="supervisors"
                >
                    <b-form-input
                        id="supervisors"
                        v-model="contractDiary.supervisors"
                        type="text"
                        size="sm"
                        class="no-margin"
                    />
                </b-form-group>
            </div>
        </b-row>
        <b-row>
            <div class="col-sm-6 pr-2">
                <span class="span-title">{{ $t('contract_diary.events') }}</span>
                <b-textarea
                    id="events"
                    v-model="contractDiary.events"
                    rows=7
                    :state="contractDiary.events && contractDiary.events.length > 3 || (contractDiary.events.length > 3 && contractDiary.events.length < 500)"
                    size="sm"
                />
                <span class="tip-light">
                            {{ contractDiary.events ? contractDiary.events.length : 0 }} / 500
                        </span>
            </div>
            <div class="col-sm-6 pr-2">
                <span class="span-title">{{ $t('contract_diary.deviations') }}</span>
                <b-textarea
                    id="deviations"
                    v-model="contractDiary.deviations"
                    rows=7
                    :state="contractDiary.deviations && contractDiary.deviations.length > 1 && contractDiary.deviations.length < 500"
                    size="sm"
                />
                <span class="tip-light">
                        {{ contractDiary.deviations ? contractDiary.deviations.length : 0 }} / 500
                        </span>
            </div>
        </b-row>
        <transition name="fade">
            <div  v-if="elyVisible" class="nopads">
                <b-row>
                    <div class="col-sm-6 pr-2">
                        <b-row class="nopads">
                            <span class="span-title">{{ $t('contract_diary.media_contacts_info_title') }}</span>
                        </b-row>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.media_contacts_received" :value="true">{{ $t('common.yes') }}</b-form-radio>
                        </div>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.media_contacts_received" :value="false">{{ $t('common.no') }}</b-form-radio>
                        </div>
                    </div>
                    <div class="col-sm-6 pr-2 pb-2 mt-4">
                        <b-textarea
                            id="mediaContactsInfo"
                            v-model="contractDiary.media_contacts_info"
                            rows=3
                            :disabled="!contractDiary.media_contacts_received"
                            :state="contractDiary.media_contacts_info.length < 500"
                            size="sm"
                        />
                        <span class="tip-light">
                            {{ contractDiary.media_contacts_info.length }} / 500
                            </span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-6 pr-2">
                        <b-row class="nopads">
                            <span class="span-title">{{ $t('contract_diary.customer_contacts_info_title') }}</span>
                        </b-row>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.customer_contacts_received" :value="true">{{ $t('common.yes') }}</b-form-radio>
                        </div>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.customer_contacts_received" :value="false">{{ $t('common.no') }}</b-form-radio>
                        </div>
                    </div>
                    <div class="col-sm-6 pr-2 pb-2 mt-4">
                        <b-textarea
                            id="customerContactsInfo"
                            v-model="contractDiary.customer_contacts_info"
                            rows=3
                            :disabled="!contractDiary.customer_contacts_received"
                            :state="!contractDiary.customer_contacts_received || (contractDiary.customer_contacts_info.length > 1 && contractDiary.customer_contacts_info.length < 500)"
                            size="sm"
                        />
                        <span class="tip-light">
                        {{ contractDiary.customer_contacts_info.length }} / 500
                        </span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-6 pr-2">
                        <b-row class="nopads">
                            <span class="span-title">{{ $t('contract_diary.citizen_contacts_info_title') }}</span>
                        </b-row>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.citizen_contacts_received" :value="true">{{ $t('common.yes') }}</b-form-radio>
                        </div>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.citizen_contacts_received" :value="false">{{ $t('common.no') }}</b-form-radio>
                        </div>
                    </div>
                    <div class="col-sm-6 pr-2 pb-2 mt-4">
                        <b-textarea
                            id="citizenContactsInfo"
                            v-model="contractDiary.citizen_contacts_info"
                            rows="3"
                            :disabled="!contractDiary.citizen_contacts_received"
                            :state="contractDiary.citizen_contacts_info.length <= 500"
                            size="sm"
                        />
                        <span class="tip-light">
                        {{ contractDiary.citizen_contacts_info.length }} / 500
                        </span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-6 pr-2">
                        <b-row class="nopads">
                            <span class="span-title">{{ $t('contract_diary.authorities_assistance') }}</span>
                        </b-row>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.authorities_assistance" :value="true">{{ $t('common.yes') }}</b-form-radio>
                        </div>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.authorities_assistance" :value="false">{{ $t('common.no') }}</b-form-radio>
                        </div>
                    </div>
                    <div class="col-sm-6 pr-2 pb-2 mt-4">
                        <span class="span-title">{{ $t('contract_diary.hours') }}</span>
                        <b-form-input
                            id="authoritiesInfoHours"
                            v-model="contractDiary.authorities_assistance_hours"
                            type="number"
                            min="0"
                            :disabled="!contractDiary.authorities_assistance"
                            :state="!contractDiary.authorities_assistance || contractDiary.authorities_assistance_hours > 0"
                            size="sm"
                        />
                        <span class="span-title">{{ $t('contract_diary.description') }}</span>
                        <div class="col-12 nopads">
                            <b-textarea
                                id="authoritiesInfo"
                                v-model="contractDiary.authorities_assistance_info"
                                rows=3
                                :disabled="!contractDiary.authorities_assistance"
                                :state="!contractDiary.authorities_assistance || (contractDiary.authorities_assistance_info.length > 1 && contractDiary.events.length < 500)"
                                size="sm"
                            />
                            <span class="tip-light">
                               {{ contractDiary.authorities_assistance_info.length }} / 500
                            </span>
                        </div>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6 pr-2">
                        <b-row class="nopads">
                            <span class="span-title">{{ $t('contract_diary.exceptional_weather') }}</span>
                        </b-row>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.exceptional_weather" :value="true">{{ $t('common.yes') }}</b-form-radio>
                        </div>
                        <div class="row">
                            <b-form-radio v-model="contractDiary.exceptional_weather" :value="false">{{ $t('common.no') }}</b-form-radio>
                        </div>
                    </div>

                    <div class="col-sm-6 pr-2 pb-2 mt-4">
                        <span class="span-title">{{ $t('contract_diary.time') }}</span>
                        <datetime
                            id="weatherTime"
                            class="col-12 nopads"
                            v-model="contractDiary.exceptional_weather_time"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            :disabled="!contractDiary.exceptional_weather"
                            v-bind:class="{missing: contractDiary.exceptional_weather && !contractDiary.exceptional_weather_time, complete: !contractDiary.exceptional_weather || contractDiary.exceptional_weather_time}"
                        />

                        <span class="span-title">{{ $t('contract_diary.location') }}</span>
                        <b-form-input
                            id="weatherLocation"
                            class="col-12"
                            v-model="contractDiary.exceptional_weather_location"
                            type="text"
                            :disabled="!contractDiary.exceptional_weather"
                            :state="!contractDiary.exceptional_weather || (contractDiary.exceptional_weather_location.length > 1)"
                        />

                        <span class="span-title">{{ $t('contract_diary.description') }}</span>
                        <div class="col-12 nopads">
                            <b-textarea
                                class="col-12"
                                id="authoritiesInfo"
                                v-model="contractDiary.exceptional_weather_info"
                                rows=3
                                :disabled="!contractDiary.exceptional_weather"
                                :state="!contractDiary.exceptional_weather || (contractDiary.exceptional_weather_info.length > 1 && contractDiary.exceptional_weather_info.length < 500)"
                                size="sm"
                            />
                            <span class="tip-light">
                                {{ contractDiary.exceptional_weather_info.length }} / 500
                            </span>
                        </div>
                    </div>
                </b-row>
            </div>
        </transition>
        <div v-if="!elyVisible" class="col-12">
            <!-- PHOTOS -->
            <b-row v-if="contractDiary.photos && contractDiary.photos.length > 1" class="nopads text-center">
                <div class="col-sm-4 nopads pl-1 multi-img-row" v-for="(photo, index) in contractDiary.photos" v-bind:key="index">
                    <ImageView
                        :img-size="'sm'"
                        :url="getPhotoUrl(contractDiaryPhotoUrl, photo)"
                        :contain="true"
                        :height="'300px'"
                        @click="openImage"
                    />
                </div>
            </b-row>
            <b-row v-else-if="contractDiary.photos && contractDiary.photos.length > 0" class="nopads text-center">
                <div class="col-12 nopads">
                    <ImageView
                        :img-size="'sm'"
                        :url="getPhotoUrl(contractDiaryPhotoUrl, contractDiary.photos[0])"
                        :contain="true"
                        :height="'300px'"
                        @click="openImage"
                    />
                </div>
            </b-row>
        </div>
        <b-row v-if="!elyVisible">
            <div v-if="!elyVisible" class="col-12 p-2">
                <span class="span-title pl-1">{{ $t('contract_diary.add_photo') }}</span>
                <div
                    v-for="(path, counter) in newPhotos"
                    :key="counter"
                    class="col-sm-12 nopads"
                >
                    <b-form-group
                        class="title"
                        label-for="counter"
                        v-if="counter === 0 || isAttachmentSet(newPhotos, counter-1)"
                    >
                        <b-form-file
                            v-model="newPhotos[counter]"
                            size="sm"
                            accept="image/jpeg, image/jpg, image/png"
                            :placeholder="$t('equipment.photo_placeholder')"
                        />
                    </b-form-group>
                </div>
            </div>
        </b-row>
        <!-- Fullscreen photo -->
        <transition name="fade">
            <fullscreen-image
                v-if="fullScreenPhotoData"
                :largeImages="fullScreenPhotoData"
                :panelIndex="panelIndex"
                @close="closePhoto"/>
        </transition>
        <div class="col-sm-12 button-container pb-3 pt-4 mt-5">
            <b-button
                variant="secondary"
                class="form-button"
                @click.stop="$emit('close')"
            >
                {{ $t('common.cancel') }}
            </b-button>
            <b-button
                variant="success"
                class="form-button"
                :disabled="loading"
                @click.stop="submitContractDiary"
            >
          <span v-if="contractDiary.id > 0">
            {{ $t('contract_diary.save_contract_diary') }}
          </span>
                <span v-else>
            {{ $t('contract_diary.add_contract_diary') }}
          </span>
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {userHelper} from "@/components/mixins/UserMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {mapHelper} from "../mixins/MapMixin";
import OpenContractFilter from "@/components/contract/OpenContractFilter.vue";
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import ImageView from "@/components/view/ImageView.vue";
import {Pagination} from "@egjs/flicking-plugins";
import FullscreenImage from "@/components/view/FullscreenImage.vue";

export default {
    name: 'ContractDiaryEditor',
    components: {FullscreenImage, ImageView, OpenContractFilter},
    mixins: [workManagementHelper, restApi, userHelper, mapHelper, timeUtils, attachmentHelper],
    props: {
        value: {
            type: Object,
            default: null
        },
        reportListView: {
            type: Boolean,
            default: false
        },
        showElyFields: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            contractDiary: null,
            options: {
                preventDefaultOnDrag: true,
                circular: true
            },
            newPhotos: new Array(5),
            fullScreenPhotoData: null,
            plugins: [new Pagination({ type: 'bullet' })],
            contract: null,
            dutyOfficer: null,
            users: [],
            panelIndex: 0,
            elyVisible: false,
        }
    },
    created() {
        this.fetchUsers()
        this.elyVisible = this.showElyFields
        this.contractDiary = this.value
        this.contract = this.contractDiary.contract ? this.contractDiary.contract : null
        this.dutyOfficer = this.contractDiary.duty_officer ? this.contractDiary.duty_officer.id : null
    },
    computed: {
        now() {
            return new Date().toISOString();
        },
    },
    methods: {
        convertLocalToUTC(dateString) {
            const localDate = new Date(dateString);
            const utcOffset = localDate.getTimezoneOffset() * 60000; // Offset in milliseconds
            const utcDate = new Date(localDate.getTime() - utcOffset);

            return utcDate.toISOString(); // ISO string format for backend
        },
        setDefaultValues: function (value) {
            let contractDiary = JSON.parse(JSON.stringify(value))
            if (this.dutyOfficer) {
                const user = this.users.find(user => user.id === this.dutyOfficer);
                if (user) {
                    contractDiary.duty_officer = user;
                }
            }
            if(this.contract) {
                contractDiary.contract = this.contract
            } else {
                EventBus.$emit('show-alert', this.$t('contract_diary.invalid_contract'))
            }
            if(this.contractDiary.record_date) {
                contractDiary.record_date = this.convertLocalToUTC(this.contractDiary.record_date);
            }
            return contractDiary
        },

        submitContractDiary: function () {
            let jsonContractDiary = this.setDefaultValues(this.contractDiary)
            if (jsonContractDiary !== undefined) {
                this.loading = true
                if (this.contractDiary.id < 1) {
                    this.restAdd(this.contractDiaryUrl, jsonContractDiary, this.success, this.fail)
                } else {
                    this.restUpdate(this.contractDiaryUrl, jsonContractDiary, this.success, this.fail)
                }
            }
        },

        success: function (response) {
            var photos = this.getValidAttachments(this.newPhotos)
            if (photos && photos.length > 0 && response) {
                this.uploadPhotos(photos, response)
            } else {
                this.loading = false
                EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
                this.$emit('closeAndUpdate')
            }
        },

        fail() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },

        setContract: function (contract) {
            this.contract = contract
            this.elyVisible = this.contract && this.contract.ely_contract
        },
        getPhotoUrl: function (baseUrl, photo) {
            return baseUrl + '/' + this.contractDiary.id + '/' + photo.id
        },
        openImage: function () {
            const flicking = this.$refs.flicking
            const imageData = this.$refs.image
            this.fullScreenPhotoData = []
            imageData.forEach(src => {
                this.fullScreenPhotoData.push(src)
            })
            this.panelIndex = flicking.index
        },
        closePhoto() {
            this.fullScreenPhotoData = null
        },
        uploadPhotos: function (photos, response) {
            if (photos && photos.length > 0) {
                this.loading = true
                let formData = new FormData()
                formData.append('diary_id', response.id)
                photos.forEach(photoFile => {
                    if (photoFile !== undefined && photoFile !== null) {
                        formData.append('photos[]', photoFile)
                    }
                })
                this.restPostWithFile(this.contractDiaryPhotoUrl, {}, formData, this.photoSuccess, this.fail)
            }
        },
        photoSuccess: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
            this.$emit('closeAndUpdate')
        },
        fetchUsers: function () {
            this.loading = true;
            this.users = []
            this.restFetchParams(this.userCompanyUrl, {active: 1}, this.handleUserResponse)
        },

        handleUserResponse: function (response) {
            if (response && response.data) {
                response.data.forEach(function (user) {
                    this.users.push({
                        last_name: user.last_name,
                        first_name: user.first_name,
                        id: user.id
                    })
                }, this)
            }
            this.loading = false
        },
    }
}
</script>
