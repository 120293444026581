<template>
    <div class="col-sm-12 nopads">
        <h4 class="form-header">{{ $t('contracts.external.daily_trips')}}</h4>
        <span class="tip-light">{{ $t('contracts.external.trip_search_hint')}}</span>
        <b-row class="nopads">
            <div class="col-sm-6 pl-1 pr-1">
                <contract-order-filter
                    v-model="order"
                    :contract-id="contract ? contract.id : undefined"
                    :show-executor-text="true"
                    :show-info="true"
                />
            </div>
            <div class="col-sm-2 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('trip_list.search_with_road_num') }}</span>
                <b-form-group
                    class="title nopads"
                >
                    <b-form-input
                        id="roadInput"
                        v-model="roadNumber"
                        type="number"
                        size="sm"
                        class="no-margin"
                        :placeholder="$t('trip_list.search_with_road_num')"
                    />
                </b-form-group>
            </div>
            <div class="col-sm-2 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('trip_list.search_with_road_sec') }}</span>
                <b-form-group
                    class="title nopads"
                    label-for="roadSecFrom"
                >
                    <b-form-input
                        id="roadSecFrom"
                        v-model="roadSecFrom"
                        type="number"
                        size="sm"
                        class="no-margin"
                        :placeholder="$t('trip_list.road_sec_from')"
                    />
                </b-form-group>
            </div>
            <div class="col-sm-2 nopads">
                <span class="span-title">{{ $t('trip_list.search_with_road_sec') }}</span>
                <b-form-group
                    class="title nopads"
                    label-for="roadSecTo"
                >
                    <b-form-input
                        id="roadSecTo"
                        v-model="roadSecTo"
                        type="number"
                        size="sm"
                        class="no-margin"
                        :placeholder="$t('trip_list.road_sec_to')"
                    />
                </b-form-group>
            </div>
        </b-row>
        <div class="col-sm-12 nopads pt-2 pb-2 text-right">
            <b-button
                variant="primary"
                class="result-button"
                :disabled="!order"
                v-on:click.stop="searchTrips()"
            >
                {{ $t('ins_report.search') }}
            </b-button>
        </div>
        <span v-if="results.length > 0" class="tip-light">{{ $t('contracts.external.trip_select_hint')}}</span>
        <div class="list-content">
            <b-table
                small
                id="resultTable"
                ref="resultTable"
                striped
                :items="results"
                :fields="fields"
                selectable
                :select-mode="selectMode"
                selected-variant="success"
                @row-selected="rowSelected"
            >
                <template v-slot:cell(mode)="row">
                    {{ getTripModeString    (row.value) }}
                </template>
                <template v-slot:cell(start_time)="row">
                    {{ toLocalTime(row.value) }}
                </template>
                <template v-slot:cell(end_time)="row">
                    {{ toLocalTime(row.value) }}
                </template>
                <template v-slot:cell(map)="row">
                    <font-awesome-icon
                        :id="'coord_'+row.item.id"
                        icon="map"
                        class="row-icon"
                        style="color: #4AAE9B"
                        @click.stop="showTripOnMap(row.item.id)"
                    />
                </template>
            </b-table>
        </div>
        <div class="col-sm-12 nopads pt-2 pb-2 text-right">
            <b-button
                variant="info"
                class="result-button"
                :disabled="selectedTrips.length < 1"
                v-on:click.stop="actionButtonClick"
            >
                {{ buttonText }}
            </b-button>
        </div>
        <contract-modal
            v-if="mapVisible"
            ref="contractModal"
            @close="mapVisible = false"
            :selected-report-type="selectedReportType"
            :map-visible="mapVisible"
            :map-trip-id="mapTripId"
            :results="results"
        />
        <!-- TODO - Trips -->
        <!-- TODO - A way to choose, from which trips to generate reports -->
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {workManagementHelper} from '../../mixins/WorkManagementMixin'
import {userHelper} from "@/components/mixins/UserMixin";
import ContractOrderFilter from "@/components/order/ContractOrderFilter";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {vehicleHelper} from "@/components/mixins/VehicleMixin";
import ContractModal from "@/components/contract/external/ContractModal.vue";
import {contractHelper, contractReportTypes} from "@/components/mixins/ContractMixin";

export default {
    name: 'ContractDailyTrips',
    components: {ContractModal, ContractOrderFilter},
    mixins: [workManagementHelper, restApi, userHelper, timeUtils, vehicleHelper, contractHelper],
    props: {
        contract: {
            type: Object,
            default: null
        },
        date: {
            type: Number,
            default: new Date().getTime()
        },
        withGeom: {
            type: Boolean,
            default: false
        },
        selectedReportType: {
            type: Number,
            default: null
        },
        buttonText: {
            type: String,
            default() {
                return this.$t('contracts.external.create_report')
            }
        }
    },
    data: function () {
        return {
            loading: false,
            order: null,
            roadNumber: null,
            roadSecFrom: null,
            roadSecTo: null,
            results: [],
            selectedTrips: [],
            mapTripId: null,
            mapVisible: false,
            map: null,
            mapTrip: null,
            fields:  [
                {
                    key: 'company_name',
                    label: this.$t('users.company'),
                    sortable: true
                },
                {
                    key: 'driver',
                    label: this.$t('trip_list.driver'),
                    sortable: true
                },
                {
                    key: 'vehicle',
                    label: this.$t('trip_list.vehicle'),
                    sortable: true
                },
                {
                    key: 'mode',
                    label: this.$t('trip_list.mode'),
                    sortable: true
                },
                {
                    key: 'order',
                    label: this.$t('vehicle_position.order'),
                    sortable: true
                },
                {
                    key: 'start_time',
                    label: this.$t('trip_list.start_time'),
                    sortable: true
                },
                {
                    key: 'start_road',
                    label: this.$t('trip_list.start_loc'),
                    sortable: true
                },
                {
                    key: 'end_time',
                    label: this.$t('trip_list.end_time'),
                    sortable: true
                },
                {
                    key: 'end_road',
                    label: this.$t('trip_list.end_loc'),
                    sortable: true
                },
                {
                    key: 'map',
                    label: this.$t('menu.map'),
                    sortable: false
                }
            ]
        }
    },
    computed: {
        isMaterialReport() {
            return this.selectedReportType === contractReportTypes.REPORT_MATERIAL
        },
        selectMode() {
            if (this.isMaterialReport) {
                return 'single'
            } else {
                return 'multi'
            }
        },
        actionButtonText() {
            if (this.isMaterialReport) {
                return 'contracts.create_report'
            } else {
                return 'contracts.external.create_report'
            }
        }
    },

    watch: {
        mapVisible: function (val) {
            if (!val) {
                this.mapTrip = null
                this.mapTripId = null
            }
            if (this.$refs.contractModal) {
                this.$refs.contractModal.modalVisible = val
            }
        }
    },
    methods: {

        actionButtonClick() {
            if (this.isMaterialReport) {
                this.showTripOnMap(this.selectedTrips[0].id)
            } else {
                this.$emit('createReports', this.getParams(), this.getSelectedTrips())
            }
        },


        resetSelection() {
            this.selectedTrips = []
            this.results = []
        },
        getSelectedTrips() {
           let results = []
           this.selectedTrips.forEach(trip => {
               const item = {
                   vehicle_id: trip['vehicle_id'],
                   driver_id: trip['driver_id'],
                   start_time: trip['start_time'],
                   end_time: trip['end_time'],
                   order_id: trip['order_id']
               }
               if (this.withGeom && trip.geometry.length > 0) {
                   const startPoint = trip.geometry[0]
                   const endPoint = trip.geometry[trip.geometry.length-1]
                   item.start_lon = startPoint.x
                   item.start_lat = startPoint.y
                   item.end_lon = endPoint.x
                   item.end_lat = endPoint.y
               }
               results.push(item)
           })
           return results
        },
        getParams(withGeom = 0) {
            let params = {
                contract: this.contract.id,
                customer: this.contract.customer.id,
                from: this.getStartOfTheDay(this.date).toISOString(),
                to: this.getEndOfTheDay(this.date).toISOString(),
                with_geom: this.withGeom ? 1 : withGeom
            }
            if (this.order) {
                params.order = this.order.id
            }
            if (this.roadNumber > 0) {
                params.road_num = this.roadNumber
            }
            if (this.roadSecFrom > 0) {
                params.road_sec_from = this.roadSecFrom
            }
            if (this.roadSecTo > 0) {
                params.road_sec_to = this.roadSecTo
            }
            return params
        },
        searchTrips(withGeom = 0) {
            this.loading = true
            this.results = []
            this.selectedTrips = []
            this.mapTrip = null
            this.mapVisible = false
            this.restFetchParams(this.tripUrl, this.getParams(withGeom), this.handleTrips)
        },

        handleTrips(response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
                if (this.mapTripId){
                    this.mapVisible = true
                }
            }
            // TODO - Error handling
        },

        rowSelected: function (items) {
            this.selectedTrips = items
        },

        showTripOnMap(tripId) {
            this.mapTripId = tripId
            const tripsHaveGeometry = this.results.some((trip) => trip.geometry);
            if (!tripsHaveGeometry) {
                this.searchTrips(1);
            } else {
                this.mapVisible = true
            }
        },

    }
}
</script>
<style scoped>

</style>
